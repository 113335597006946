<script
    lang="ts"
    setup
>
    type Item = {
        title: string
        subtitle: string
        file: string
        routeName: string
    }

    const { t } = useLang()

    const items = computed<Item[]>(() => [
        {
            title: t('comparison-with') + ' Jivo',
            subtitle: t('comparison-1'),
            file: 'tariffs-2',
            routeName: 'comparison-jivo',
        },
        {
            title: t('comparison-with') + ' Helpcrunch',
            subtitle: t('comparison-25'),
            file: 'tariffs-3',
            routeName: 'comparison-helpcrunch',
        },
    ])
</script>

<template>
    <div class="flex max-tablet:flex-col gap-6 tablet:gap-9">
        <AppLink
            v-for="item in items"
            :key="item.title"
            class="group tablet:basis-1/2"
            :to="{ name: item.routeName }"
        >
            <AppButton
                wrapper
                class="
                    flex
                    gap-4
                    max-tablet:w-full
                    tablet:h-full
                    tablet:w-full
                    items-center
                    !p-6
                    !border
                    !border-solid
                    !border-secondary-active
                    rounded-[20px]
                    group-hover:!border-black
                "
            >
                <AppPublicImage :file="item.file" />

                <div class="flex flex-col gap-2 leading-[120%] max-w-[234px]">
                    <div class="text-[20px] font-medium">
                        {{ item.title }}
                    </div>

                    <div
                        class="
                            text-secondary-text
                            group-hover:text-black
                        "
                    >
                        {{ item.subtitle }}
                    </div>
                </div>

                <AppIconChevronRight
                    class="
                        self-center
                        ml-auto
                        transition-transform
                        group-hover:[&>path]:fill-black
                        group-hover:translate-x-1
                    "
                />
            </AppButton>
        </AppLink>
    </div>
</template>
